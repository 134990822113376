<template>
    <div>
        <ek-dialog
            @ok="submit"
            @close="reset"
            @search="setSearch"
            v-if="isCode"
            :title="'اضافة رمز'"
            btnText="رمز جديد"
            placeholder="ابحث عن الرمز المحدد"
            ref="dialog"
        >
            <template #body>
                <validationObserver ref="codeForm">
                    <b-form>
                        <ek-input-select
                            :options="infoPackage"
                            v-model="dtoCode.packageId"
                            label="اختر الحزمة"
                            name="اختر الحزمة"
                            :rules="[
                                { type: 'required', message: ' الحزمة مطلوبة' }
                            ]"
                        >
                        </ek-input-select>
                        <ek-input-text
                            label="الحسم"
                            name="الحسم"
                            v-model="dtoCode.discount"
                            :rules="[
                                { type: 'required', message: 'الحسم مطلوب' }
                            ]"
                        >
                        </ek-input-text>
                    </b-form>
                </validationObserver>
            </template>
        </ek-dialog>
        <div v-else class="d-flex justify-content-end align-items-center">
            <b-button variant="primary" class="p-50" @click="addPackageBtn">
                <span class="mr-1">اضافة حزمة</span>
                <unicon style="fill:#fff;" name="plus"></unicon>
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            isCode: ({ activationCode }) => activationCode.isCode,
            infoPackage: ({ activationCode }) => activationCode.infoPackage,
            dtoCode: ({ activationCode }) => activationCode.dtoCode
        })
    },
    methods: {
        submit() {
            this.$refs["codeForm"].validate().then(suc => {
                if (suc) {
                    this.addCode(this.dtoCode);
                    this.$store.commit("RESET_CODE_DTO");
                    this.$refs.dialog.close();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "studentName", "sellPointName"],
                query
            });
        },
        reset() {
            this.$refs["codeForm"].reset();
            this.$store.commit("RESET_CODE_DTO");
        },
        addPackageBtn() {
            this.$router.push("/admin/activationCode/package");
        },
        ...mapActions(["getInfoPackage", "addCode"])
    },
    created() {
        this.getInfoPackage();
    }
};
</script>

<style></style>
